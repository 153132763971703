import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueCookies from "vue-cookies";

import router from '@/router'
import store from '@/stores'

//common component
import '@/components'
//lib
import { BootstrapVue } from 'bootstrap-vue'
import Vuetify from 'vuetify'
import VueDaumPostcode from "vue-daum-postcode"
import draggable from 'vuedraggable'
import VueToast from 'vue-toast-notification';
import RangePicker from 'vue2-datepicker';
import { Bar, Doughnut } from 'vue-chartjs'

//style
import '@/styles/index.scss'
import 'vue-toast-notification/dist/theme-default.css';
import 'vue2-datepicker/index.css';

Vue.use(VueRouter)
Vue.use(Vuetify);
Vue.use(BootstrapVue);
Vue.use(VueDaumPostcode);
Vue.use(draggable);
Vue.use(VueToast);
Vue.use(RangePicker);
Vue.use(Bar)
Vue.use(Doughnut)
Vue.use(VueCookies);

Vue.config.productionTip = false
Vue.$cookies.config("7d");

import format from '@/utils/format'
import status from '@/utils/status'
Vue.prototype.$notice = (res, title, type) => {
    const text = `${title}${format.checkBatchimEnding(title)} ${status.fun[type]}`

    if(res) {
        Vue.$toast.success(`${text}하였습니다.`)
    } else {
        Vue.$toast.error(`${text}하던 중 오류가 발생했습니다. 다시 시도해주세요.`)
    }
}

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
