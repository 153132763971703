import api from '@/api'
import format from '@/utils/format'

const state = {
	params : {
		keyword : '',
		page : 1,
		count : 30
	},
	list : [],
	count: 1,
	headers: [],
}

const getters = {
	headers: state => {
		return state.headers.map(header => {
			return {
				key: header.headerKey,
				label: header.text,
				isSort: header.isSort,
				sort: header.sort,
				link: header.link,
				formatter: (value, key, item) => {
					if(key.indexOf('Date') >= 0) {
						value = format.date({date: value, onlyDate: true})
					}
					return value;
				}
			}
		})
	},
	list: state => {
		return state.list.map(item => {
			item.isNew = item.createDatetime >= format.date({date: new Date(), onlyDate: true}) ? true : false
			return item
		})
	},
	count: state => state.count,
	params: state => state.params,
}


const mutations = {
	setList: (state, data) => {
		state.list = [...data.table.list]
		state.count = data.table.count
		state.headers = [...data.table.header]
	},
	setParams: (state, data) => {
		state.params = {...state.params, ...data}
	},
	resetParams: (state) => {
		state.params = {
			keyword : '',
			page : 1,
			count : 30
		}
	}
}

// async 됨 
const actions = {
	loadList: async ({commit, state}) => {
		const res = await api.client.getList(state.params);
		commit('setList', res)
	}
}


export default { namespaced: true, state, getters, mutations, actions }