<template>
	<div id="sidebar">
		<router-link class="logo" to="/">
			<img src="@/assets/img/logo.png">
		</router-link>
		<div class="quick-menu">
			<h5 class="menu-title" style="margin-bottom: 10px;">빠른 메뉴</h5>
			<e-btn 
				:icon="require('@/assets/img/button-doc.png')"
				text="업무생성"
				@click="$router.push('/project/form/add')"
			/>
			<e-btn 
				:icon="require('@/assets/img/button-add-person.png')"
				text="고객추가"
				@click="$router.push('/client/form/add')"
			/>
		</div>
		<nav class="menu">
			<h5 class="menu-title">작업</h5>
			<router-link 
				v-for="(item,index) in menu" 
				:key="`menu-${index}`"
				:to="item.path"	
			>
				<e-btn 
					:icon="require(`@/assets/img/${item.img}`)"
					:text="item.name"
					:styleType="$route.fullPath.includes(item.page) ? '' : $route.fullPath === item.path ? '' : 'text'"
				/>
			</router-link>
		</nav>
		<div class="profiles" v-if="profile.name">
			<v-avatar>
				<span class="white--text text-h5">{{profile.name.slice(0,1)}}</span>
			</v-avatar>
			<div class="txtbox">
				<b>{{profile.name}}</b>
				<p>{{profile.email}}</p>
			</div>
		</div>
	</div>
</template>

<script>
import {menu} from '@/utils/menu'
import { mapGetters, mapActions } from 'vuex'

export default {
	props: {
		profile: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			menu: menu,
		}
	},
	// created() {
	// 	this.getProfile()
	// },
	// computed: {
	// 	...mapGetters("user", ["profile"])
	// },
	// methods: {
	// 	...mapActions("user", ["getProfile"])
	// }
}
</script>