import api from '@/api'

const state = {
	loginForm: {
		loginId: '',
		password: '',
		keepLogin: true,
	},
	profile: {
		id: '',
		login_id: "",
		password: "",
		name: '',
		email: '',
		permission: "",
		create_datetime: "",
		update_datetime: "",
		delete_datetime: null
	},
	pwForm: {
		email: '',
		password: '',
		newPassword: '',
	}
}

const getters = {
	loginForm: state => state.loginForm,
	pwForm: state => state.pwForm,
	profile: state => state.profile
}

const mutations = {
	setLoginForm: (state, data) => {
		state.loginForm = {...data}
	},
	setPwForm: (state, data) => {
		state.pwForm = {...data}
	},
	setProfile: (state, data) => {
		state.profile = {...data}
	},
}

const actions = {
	login: async ({commit, state}, params) => {
		const res = await api.user.login(params);
		return res;
	},
	autoLogin: async({commit, state}) => {
		const res = await api.user.autoLogin()
		return res;
	},
	resetPw: async ({commit, state}) => {
		const res = await api.user.resetPw(state.pwForm)
		return res;
	},
	getProfile: async({commit, state}) => {
		const res = await api.user.getProfile() 
		commit('setProfile', res)
	},
}

export default { namespaced: true, state, getters, mutations, actions }