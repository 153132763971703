<template>
	<b-form-group
		:class="{required: required, 'is-col': isCol, 'disabled': disabled}"
		:label="title"
	>
		<div class="detail-btns">
			<slot name="detail-btns" />
		</div>
		<!--메모-->
		<b-textarea
			v-if="type === 'textarea'"
			no-resize
			rows="5"
			:value="value"
			@input="onInput"
			:placeholder="placeholder ? placeholder : format.placeholder(title)"
			:state="validation"
		/>
		<!-- 검색 + 입력 가능 한 필드 -->
		<div class="form-search" v-else-if="type === 'search'" :class="{right: dropright}">
			<b-input-group>
				<b-form-input 
					:value="value" 
					:formatter="formatter"
					@input="onInput"
					@focus="showOption = true"
					@blur="close"
					size="lg"
					autocomplete="off"
					:placeholder="placeholder ? placeholder : format.placeholder(title)"
					:state="validation"
					:disabled="disabled"
				/>
				<slot name="close"/>
			</b-input-group>
			<b-list-group :class="{active: showOption}">
				<b-list-group-item
					button
					v-for="(option,index) in options"
					:key="`search-${option.value}-${index}`"
					@click="onSelect(option)"
					v-show="setSearchResult(option, value)"
				>	
					<p v-html="format.highlighted(option.text, value)"/>
				</b-list-group-item>
			</b-list-group>
		</div>
		<!--선택만 가능한 필드-->
		<b-dropdown class="form-select" v-else-if="type === 'select'" :dropright="dropright">
			<template #button-content>
				<b-form-input 
					readonly 
					:placeholder="placeholder ? placeholder : format.placeholder(title)"
					:value="value"
					:formatter="formatter"
					:state="validation"
					size="lg"
					:disabled="disabled"
				/>
			</template>
			<b-dropdown-group>
				<b-dropdown-item-button
					v-for="(option,index) in options"
					:key="`select-${option.name}${index}`"
					@click="onSelect(option)"
				>
					{{option.text}}
				</b-dropdown-item-button>
			</b-dropdown-group>
		</b-dropdown>
		<!--여러 개 선택 가능한 필드 multiple-->
		<div 
			class="form-multi-select"  
			v-else-if="type === 'multi-select'" 
			:class="{right: dropright}"
			v-click-outside="close"
		>
			<b-input-group @click="e => $refs['multi-input'].focus()">
				<div class="badges">
					<b-badge
						class="badge"
						v-for="(item, index) in values"
						:key="`multi-${index}`"
					>
						{{ item }}
						<button type="button" class="close" @click="deleteValues(item)"/>
					</b-badge>
					<b-form-input 
						ref="multi-input"
						@focus="showOption = true"
						@input="onInput"
						v-model="multiSelectValue"
						autocomplete="off"
						@keyup.enter="e => addValues(e)"
					/>
				</div>
				<button type="button" class="select-arrow" @click="showOption = !showOption"></button>
			</b-input-group>
			<b-list-group :class="{active: showOption}">
				<b-list-group-item
					button
					v-for="(option,index) in options"
					:key="`search-${option.value}-${index}`"
					class="check-item custom-control custom-checkbox"
					v-show="setSearchResult(option, multiSelectValue)"
				>	
					<input 
						type="checkbox" 
						class="custom-control-input"
						@input="e => onChecked(e, option)"
						:id="`option-${formKey}${index}`"
						:checked="selected.includes(option.text)" 
						:value="option.value"
					/>
					<label class="custom-control-label" :for="`option-${formKey}${index}`">{{ option.text }}</label>
				</b-list-group-item>
			</b-list-group>
		</div>
		<b-form-datepicker
			v-else-if="type === 'date'"
			:value="value"
			:date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
			hide-header
			size="lg"
			:placeholder="placeholder ? placeholder : format.placeholder(title)"
			:state="validation"
			:disabled="disabled"
			@input="onInput"
		/>
		<!--switch fields-->
		<b-input-group v-else-if="(type == 'group' || type == 'test')">
			<div>
				<b-form-checkbox 
					size="lg" 
					:checked="checked" 
					:disabled="disabled"
					switch 
					@change="value => $emit('change', value)"
				/>
			</div>
			<!-- <b-form-input
				v-if="type == 'test'"
				size="lg"
				:value="value"
				@input="onInput"
				:placeholder="placeholder ? placeholder : format.placeholder(title)"
				:formatter="formatter"
				:disabled="disabled"
			/> -->
		</b-input-group>
		<!--일반 텍스트 필드-->
		<b-input-group v-else>
			<b-form-input
				size="lg"
				:value="value"
				:type="formatType"
				:readonly="readonly"
				@input="onInput"
				@keydown="e => $emit('keydown', e)"
				:placeholder="placeholder ? placeholder : format.placeholder(title)"
				:formatter="formatter"
				:state="validation"
				:disabled="disabled"
				autocomplete="false"
			/>
			<!--unit이 있을 경우-->
			<span class="unit" v-if="type === 'amount'">{{unit}}</span>
		</b-input-group>
		<b-form-invalid-feedback>{{placeholder ? placeholder : format.placeholder(title)}}</b-form-invalid-feedback>
	</b-form-group>
</template>

<script>
import format from '@/utils/format'
import Vue from 'vue'

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
        // here I check that click was outside the el and his children
        if (!(el == event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event);
        }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});

export default {
	name: 'Fields',
	props: {
		title: {
			type: String,
			default: ''
		},
		value: {
			type: [String, Number],
			default: () => ''
		},
		required: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: 'text'
		},
		placeholder: {
			type: String,
			default: ''
		},
		isCol: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		options: {
			type: Array,
			default: () => []
		},
		unit: {
			type: String,
			default: ''
		},
		selected: {
			type: Array,
			default: () => []
		},
		checked: {
			type: Boolean,
			default: false
		},
		isValidate: {
			type: Boolean,
			default: false
		},
		dropright: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		formKey: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			format: format,
			showOption: false,
			multiSelectValue: ''
		}
	},
	computed: {
		validation() {
			if(this.required) {
				return this.isValidate
			} else {
				return true
			}
		},
		formatType() {
			if(this.type == 'phone' || this.type == 'amount') {
				return 'text'
			} else {
				return this.type
			}
		},
		values() {
			if(this.type === 'multi-select') {
				return this.value ? this.value.split(',') : []
			}
		}
	},
	methods: {
		onInput(value) {
			this.$emit('input', value)
		},
		onChecked(e, option) {
			const arr = [...this.values]
			let result = [];
			if(e.target.checked) {
				result = [...arr, ...[option.text]]
			} else {
				result = arr.filter(el => el !== option.text)
			}
			this.multiSelectValue = '';
			this.$refs['multi-input'].focus();
			this.$emit('change', this.formKey, result.join(','), option.text)
		},
		onSelect(option) {
			// alert("SELECTED")
			this.$emit('select', option)
			this.showOption = false
		},
		formatter(value) {
			//전화번호 format
			if(this.type == 'phone') {
				return format.phone(value)
			//search option obj 대응
			} else if(this.type === 'search') {
				if(typeof value == 'object') {
					return value.text;
				} else {
					return value
				}
			//숫자 관련 format
			} else if(this.type == 'amount' || this.type == 'number') {
				return format.number(value)
			}

			// 숫자 자동변환시 마지막 글자 "." 인경우 예외처리 
			if(value && !isNaN(Number(value)) && value.charAt(value.length - 1) !== '.') {
				return Number(value)
			}	

			return value;
		},
		close: function() {
			setTimeout(() => {this.showOption = false}, 200)
		},
		deleteValues(val) {
			let result = this.values.filter(el => el !== val);
			this.$emit('change', this.formKey, result.join(','), val)
		},
		addValues(e) {
			let result = this.value ? this.value.split('/') : [];
			if(e.target.value) {
				result.push(e.target.value)
			}
			this.multiSelectValue = '';
			this.$emit('change', this.formKey, result.join(','), e.target.value)
		},
		setSearchResult(option, value) {
			if(value) {
				if(option.text && format.lower(option?.text).indexOf(format.lower(value)) >= 0) {
					return true;
				} 
				else if(option.sub && format.lower(option?.sub).indexOf(format.lower(value)) >= 0) {
					return true;
				}
				return false;
			} else {
				return true;
			}
		}
	}
}
</script>