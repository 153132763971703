import axios from 'axios'
import cookies from 'vue-cookies'

import client from './client'
import project from './project'
import analysis from './analysis'
import user from './user'
import dashboard from './dashboard'

class Api {
	constructor(url = process.env.VUE_APP_BASE_URL) {
		this.instance = axios.create({
			baseURL: url,
			timeout: 30000,
			headers: {
				"Content-Type": "application/json",
			}
		})
		this.user.initToken()

		// 요청 인터셉터 추가하기
		axios.interceptors.request.use(function (config) {
			// 요청이 전달되기 전에 작업 수행
			return config;
		}, function (error) {
			// 요청 오류가 있는 작업 수행
			return Promise.reject(error);
		});

		// 응답 인터셉터 추가하기
		axios.interceptors.response.use(function (response) {
			// 2xx 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
			// 응답 데이터가 있는 작업 수행
			console.log(response)
			return response;
		}, function (error) {
			// 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
			// 응답 오류가 있는 작업 수행
			return Promise.reject(error);
		});
	}

	_getInstance(_class) {
		const className = _class.name;

		// instance 최초 생성
		if (!this[className]) {
			this[className] = new _class(this.instance);
		}

		return this[className];
	}

	//고객사
	get client() {
		return this._getInstance(client)
	}
	//업무
	get project() {
		return this._getInstance(project)
	}

	//분석
	get analysis() {
		return this._getInstance(analysis)
	}

	//로그인 & 유저 정보
	get user() {
		return this._getInstance(user)
	}
	
	//대시보드
	get dashboard() {
		return this._getInstance(dashboard)
	}

	set authorization(value) {
		this.instance.defaults.headers.common["Authorization"] = value;
	}

	get authorization() {
		this.instance.defaults.headers.common["Authorization"];
	}
}

export default new Api()