import api from '@/api'
import format from '@/utils/format'

const state = {
	params: {
		page: 1,
		count: 1000,
		keyword: ''
	},
	history: {
		list: [],
		count: 1,
		headers: []
	}
}

const getters = {
	history: state => {
		state.history.headers = state.history.headers.map(header => {
			return {
				key: header.headerKey,
				label: header.text,
				sortable: header.sort,
				formatter: (value, key, item) => {
					if(key.indexOf('Date') >= 0) {
						value = format.date({date: value, onlyDate: true})
					}
					return value;
				}
			}
		})
		state.history.headers.unshift({key: 'selected', label: '전체 선택'})
		return state.history
	}
}

const mutations = {
	setHistory: (state, data) => {
		state.history.list = [...data.table.list]
		state.history.headers = [...data.table.header]
		state.history.count = data.table.list.length
	}
}

const actions = {
	loadHistory: async ({state, commit}, id) => {
		const res = await api.project.getHistory(id, state.params)
		commit('setHistory', res)
	},
	deleteHistory: async ({state, dispatch}, ids) => {
		//TODO!! 삭제 api 추가 필요
		const res = await api.project.deleteHistory(ids)
		return res;
	}
}

export default { namespaced: true, state, getters, mutations, actions }