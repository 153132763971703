import Router from 'vue-router'

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: "DashboardMain",
			props: true,
			component: () => import(
				/* webpackChunkName: "client list" */ 
				'@/views/dashboard/pages/index.vue'
			),
		},
		{
			path: '/dashboard/analysis',
			name: "DashboardAnalysis",
			props: true,
			component: () => import(
				/* webpackChunkName: "client list" */ 
				'@/views/dashboard/pages/analysis.vue'
			),
		},
		{
			path: '/dashboard/admin',
			name: "DashboardAdmin",
			props: true,
			component: () => import(
				/* webpackChunkName: "client list" */ 
				'@/views/dashboard/pages/admin.vue'
			),
		},
		{
			path: '/client/list',
			name: "ClientList",
			props: true,
			component: () => import(
				/* webpackChunkName: "client list" */ 
				'@/views/client/pages/list.vue'
			),
		},
		{
			path: '/client/form/:mode',
			name: "ClientEdit",
			props: true,
			component: () => import(
				/* webpackChunkName: "client edit" */ 
				'@/views/client/pages/edit.vue'
			),
		},
		{
			path: '/client/detail/:id',
			name: 'ClientDetail',
			props: true,
			component: () => import('@/views/client/pages/detail.vue')
		},
		{
			path: '/project/list',
			name: "ProjectList",
			props: true,
			component: () => import(
				/* webpackChunkName: "client list" */ 
				'@/views/project/pages/list.vue'
			),
		},
		{
			path: '/project/detail/:id',
			name: 'ProjectDetail',
			props: true,
			component: () => import('@/views/project/pages/detail.vue')
		},
		{
			path: '/project/form/:mode',
			name: "ProjectEdit",
			props: true,
			component: () => import(
				/* webpackChunkName: "project edit" */ 
				'@/views/project/pages/edit.vue'
			),
		},
		{
			path: '/project/:id/journal/:mode',
			name: "ProjectJournal2",
			props: true,
			component: () => import(
				/* webpackChunkName: "project edit" */ 
				'@/views/project/pages/journal2.vue'
			),
		},
		{
			path: '/project/:id/journal2/:mode',
			name: "ProjectJournal",
			props: true,
			component: () => import(
				/* webpackChunkName: "project edit" */ 
				'@/views/project/pages/journal.vue'
			),
		},
		{
			path: '/project/:id/specimen/:mode',
			name: "ProjectSpecimen",
			props: true,
			component: () => import(
				/* webpackChunkName: "project edit" */ 
				'@/views/project/pages/specimen.vue'
			)
		},
		{
			path: '/analysis/list',
			name: "AnalysisList",
			props: true,
			component: () => import(
				/* webpackChunkName: "analysis list" */ 
				'@/views/analysis/pages/list.vue'
			),
		},
		{
			path: '/member/list',
			name: "MemberList",
			props: true,
			component: () => import(
				/* webpackChunkName: "member list" */ 
				'@/views/member/pages/list.vue'
			),
		},
		{
			path: '/login',
			name: 'Signin',
			props: true,
			component: () => import(
				/*webpackChunkName: "login page"*/
				'@/views/login/signin.vue'
			)
		},
		{
			path: '/login/email',
			name: 'SubmitEmail',
			props: true,
			component: () => import(
				/*webpackChunkName: "login page"*/
				'@/views/login/email.vue'
			)
		},
		{
			path: '/login/reset',
			name: 'ResetPw',
			props: true,
			component: () => import(
				/*webpackChunkName: "login page"*/
				'@/views/login/reset.vue'
			)
		}
	],
})
export default router