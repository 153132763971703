<template>
	<div class="s-doughnut svg-item">
		<svg 
			class="donut" 
			width="100%" 
			height="100%" 
			viewBox="0 0 40 40" 
		>
			<circle 
				class="donut-ring"
				cx="20" 
				cy="20" 
				r="15.91549430918954" 
				fill="transparent" 
				stroke-width="8"
			/>
			<circle 
				class="donut-segment" 
				cx="20" 
				cy="20" 
				r="15.91549430918954" 
				fill="transparent" 
				stroke-width="8" 
				:stroke-dasharray="`${100 * ratio} ${100*(1-ratio)}`" 
				stroke-dashoffset="25"
			/>
		</svg>
	</div>
</template>

<script>
export default {
	name: 'SmallDoughnut',
	props: {
		ratio: {
			type: Number,
			default: 0
		}
	}
}
</script>