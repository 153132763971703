import api from '@/api'
import tableHeaders from '@/utils/tableHeaders'
import status from '@/utils/status'
import format from '@/utils/format'

const state = {
	projectSituation: {
		all: 0,
		wait: 0,
		summary: {},
		deptRatio: {},
		deptSummary: {},
	},
	amountSituation: {
		all: 100,
		amount: 1000000,
		unpaidAmount: 1000,
		deptSaleRatio: {},
		docTypeRatio: {},
	},
}

const getters = {
	projectSituation: state => {
		return state.projectSituation
	},
	amountSituation: state => state.amountSituation,
}

const mutations = {
	setProjectSituation: (state, data) => {

		//업무, 승인대기
		state.projectSituation.all = format.number(1234)
		state.projectSituation.isApprove = format.number(456)

		//부서별 업무 비율
		state.projectSituation.deptRatio.labels = ['토양', '대기', '수질' ]
		state.projectSituation.deptRatio.datasets = [];
		state.projectSituation.deptRatio.datasets[0] = {};
		state.projectSituation.deptRatio.datasets[0].data = [10, 20, 30]
		state.projectSituation.deptRatio.datasets[0].backgroundColor = ['#B09269', '#D88FA4', '#85AAD6']

		//부서별 업무 현황
		state.projectSituation.deptSummary.labels = ['토양', '대기', '수질' ]
		state.projectSituation.deptSummary.datasets = [];
		status.project.forEach(item => {
			state.projectSituation.deptSummary.datasets.push({
				label: item.text,
				data: [1,2,4],
				backgroundColor: item.backgroundColor
			})
		})
	},
	setAmountSituation: (state, data) => {
		//dummy data
		// state.projectSituation.deptSummary.labels = status.project.map(el => {
		// 	return el.text
		// })

		//접수된 업무
		state.amountSituation.all = format.number(315)
		state.amountSituation.amount = `${format.number(300000000)}원`
		state.amountSituation.unpaidAmount = `${format.number(300000000)}원`

		//부서별 매출 비율
		state.amountSituation.deptSaleRatio.labels = ['토양', '대기', '수질' ]
		state.amountSituation.deptSaleRatio.datasets = [];
		state.amountSituation.deptSaleRatio.datasets[0] = {};
		state.amountSituation.deptSaleRatio.datasets[0].data = [10, 20, 30]
		state.amountSituation.deptSaleRatio.datasets[0].backgroundColor = ['#B09269', '#D88FA4', '#85AAD6']

		//문서 유형별 매출 비율
		state.amountSituation.docTypeRatio.labels = ['EA', 'EB', 'EC' ]
		state.amountSituation.docTypeRatio.datasets = [];
		state.amountSituation.docTypeRatio.datasets[0] = {};
		state.amountSituation.docTypeRatio.datasets[0].data = [10, 20, 30]
		state.amountSituation.docTypeRatio.datasets[0].backgroundColor = ['#535CA8', '#E09C41', '#DE6B7A']
	},
}

const actions = {
	//업무 현황 - 업무 숫자, 승인 대기 숫자, 전체 업무 현황, 부서별 업무 비율, 부서 별 업무 현황
	loadProjectSituation: async ({commit, state}, params) => {
		commit('setProjectSituation')
	},	
	//매출 현황 - 접수된 업무, 매출 총액, 미수금, 부서별 매출 비율, 문서 유형별 매출 비율
	loadAmountSituation: async ({commit, state}, params) => {
		commit('setAmountSituation')
	}
}

export default { namespaced: true, state, getters, mutations, actions }