import api from '@/api'
import status from '@/utils/status'
import format from '@/utils/format'
import tableHeaders from '@/utils/tableHeaders'

const state = {
	settleSummary: {},
	settleList: {
		count: 1,
		list: [],
		headers: []
	},
	settle: {
		summary: [],
		detail: []
	},
	settleForm: {
		transactDate: '',
		type: '',
		bank: '',
		amount: '',
		transact_client: '',
		memo: '',
		attachment: [],
	}
}

const getters = {
	settleSummary: state => state.settleSummary,
	settleList: state => state.settleList,
	settle: state => {
		let result = {...state.settleSummary};
		let settleKeys = Object.keys(status.project_detail.settle);
		let summaryList = []
		settleKeys.forEach(key => {
			summaryList.push({
				key: key,
				name: status.project_detail.settle[key],
				value: format.dataValue(key, result, true),
				color: key == 'unpaidAmount' ? 'red' : 'black'
			})
		})
		let detailList = []
		status.project_detail.settle_list.forEach(item => {
			detailList.push({
				key: item.key, 
				name: item.name,
				value: format.dataValue(item.key, result),
				type: item.type
			})
		})
		return {
			summary: summaryList,
			detail: detailList
		}
	},
	settleForm: state => state.settleForm
}

const mutations = {
	setSettleSummary: (state, data) => {
		state.settleSummary = {...data}
		state.settleSummary.unpaidAmount = data.amount - data.paidAmount
	},
	setSettleList: (state, data) => {
		state.settleList.count = data.count;
		state.settleList.list = [...data.list];
		state.settleList.headers = tableHeaders.accountHistoryTableHeader.map(header => {
			return {
				key: header.headerKey,
				label: header.text,
				sortable: header.sort,
				formatter: (value, key, item) => {
					if(key.indexOf('Date') >= 0) {
						value = format.date({date: value, onlyDate: true})
					}
					else if(key == 'amount') {
						value = `${format.number(value)}원`
					}
					return value;
				}
			}
		})
	},
	setSettleForm: (state, data) => {
		state.settleForm = {...data};
	}
}

const actions = {
	loadSettleSummary: async ({commit}, id) => {
		const res = await api.project.getSettleSummary(id)
		commit('setSettleSummary', res)
	},
	loadSettleList: async ({commit}, id) => {
		const res = await api.project.getSettleList(id)
		commit('setSettleList', res)
	},
	addSettle: async ({commit, dispatch}, payload) => {
		const {id, body} = payload
		const res = await api.project.addSettle(id, body)
		commit('setSettleForm', {})
		dispatch('loadSettleList', id)
		return res;
	},
	deleteSettle: async ({commit, dispatch}, payload) => {
		const {id, settleId} = payload
		const res = await api.project.deleteSettle(id, settleId)
		dispatch('loadSettleList', id)
		return res;
	}
}

export default { namespaced: true, state, getters, mutations, actions }