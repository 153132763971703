import VueCookies from "vue-cookies";
class User {
	constructor(instance) {
		this.axios = instance;
	}
	async initToken() {
		const token = JSON.parse(VueCookies.get('token'))
		if(token) {
			this.axios.defaults.headers.common["Authorization"] = token;
		}
	}
	//로그인
	async login(params) {
		if(params.keepLogin) {
			VueCookies.set('signin', JSON.stringify(params));
		}
		const response = await this.axios.post(`/user/signin`, params);
		if(response.data.token) {
			VueCookies.set('token', JSON.stringify(response.data.token));
			this.axios.defaults.headers.common["Authorization"] = response.data.token;
		}
		return response;
	}
	//로그인 상태 유지 시 자동 로그인
	async autoLogin() {
		const signin = VueCookies.get('signin');
		const result = JSON.parse(JSON.stringify(signin))
		if(result?.id) {
			return result
		} else {
			return false
		}
	}
	//비밀번호 변경하기
	async resetPw() {
		//
	}
	//유저 프로필
	async getProfile() {
		const response = await this.axios.get(`/user/profile`);
		return response.data;
	}

	async getUserNameList(field, value) {
		const response = await this.axios.get(`/user/simple/name/list`, {
			params : {
				field, value
			}
		});

		return response.data.list;
	}
}

export default User