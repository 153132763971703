class Analysis {
	constructor(instance) {
		this.axios = instance;
	}
	//TODO!! api 변경 필요
	//분석 리스트
	async getList(params) {
		try {
			const response = await this.axios.get(`/test/list`, {params})
	
			return response.data;
		} catch(e) {
			return false;
		}
	}
}

export default Analysis