<template>
	<!-- 공통 템플릿 -->

	<!-- Style 적용 편의를 위해 RouterView Wrapping -->
	<div id="main-wrapper">
		<Sidebar :profile="profile"/>
		<RouterView :toPath="toRoute?.path" :fromPath="fromRoute?.path" :profile="profile"/>
		<div v-show="showWarning" style="z-index : 99999; width : 80vw; border-radius: 20px; position: fixed; left : 50%; bottom : 70px; transform: translateX(-50%); height: 80px; background : rgba(255,70,70,1)">
			<button style="font-size : 20pt; position: absolute; top : 0px; right : 20px; color : white; font-weight: bold" @click="showWarning = false">&times;</button>
			<p style="text-align: center; font-size : 13pt; font-weight: bold; margin-top : 12px; color : white">
				현재 개발 작업중으로 수시로 기능이 변경되거나 일시적으로 미동작할 수 있습니다. <br/>
				문의사항 혹은 중요작업시 <a href="https://open.kakao.com/o/g2t5N9wg" target="_blank" style="text-decoration: underline;font-size : 15pt;">카카오톡 오픈채팅</a>으로 연락주세요.
			</p>
		</div>
	</div>
	<!-- <AppFooter></AppFooter> -->
</template>

<script>
import Sidebar from '@/components/app/Sidebar'
import {mapGetters,mapActions} from 'vuex'
import VueCookies from 'vue-cookies'

export default {
	components: {
		Sidebar
	},
	data() {
		return {
			showWarning : false,
			toRoute: null,
			fromRoute: null,
		}
	},
	watch: {
		'$route'(to, from) {
			//페이지가 이동할 때 상세일 경우에는 params 유지, 기타 페이지에서 이동할 경우에는 params reset
			this.toRoute = to;
			this.fromRoute = from;
		},
	},
	async created() {
		this.init()
	},
	computed: {
		...mapGetters("user", ["profile"])
	},
	methods: {
		...mapActions('user', ['autoLogin', 'getProfile']),
		init() {
			const token = VueCookies.get('token')
			if(token) {
				this.getProfile()
			} else {
				this.$router.push('/login')
			}
		}
	}
}
</script>
