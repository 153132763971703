<template>
	<div class="page-header">
		<div class="page-title">
			<h2>{{title}}</h2>
			<h6 v-if="subtitle">{{subtitle}}</h6>
		</div>
		<div class="header-right">
			<slot/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: ''
		},
		subtitle: {
			type: String,
			default: ''
		}
	}
}
</script>