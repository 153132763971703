import api from '@/api'
import tableHeaders from '@/utils/tableHeaders'
import status from '@/utils/status'
import format from '@/utils/format'

const state = {
	issueForm: {
		id: '', //이슈 id
		content: '', //이슈 내용
	},
	issueHeaders: [],
	issueList: [],
	issueCount: 1,
	issueProjectList : [],
	issueProjectCount : 1,
	myProjectHeaders: [],
	myProjectList: [],
	myProjectCount: 1,
	myProjectChartsets: {
		labels: ['1'],
		datasets: []
	},
	myProjectStatus: [],
	deptProjectHeaders: [],
	deptProjectList: [],
	deptProjectCount: 1,
	deptProjectChartsets: {
		labels: ['1'],
		datasets: []
	},
	deptProjectStatus: [],
	projectSituation: {
		count: 1,
		WAIT: 1,
		WORK_ACCEPTED: 1,
		WORK_REQUEST: 1, 
		WORK_ANALYSIS: 1,
		WORK_DIRECTOR_REVIEW: 1,
		WORK_IMPLEMENTATION_REVIEW: 1,
		WORK_APPROVAL: 1,
		WORK_COMPLETE: 1,
	},
}

const getters = {
	issueForm: state => state.issueForm,
	issueHeaders: state => {
		return tableHeaders.dashboardIssueTableHeader.map(header => {
			return {
				key: header.headerKey,
				label: header.text,
				isSort: header.isSort,
				sort: header.sort,
				link: header.link,
				type: header.type,
				formatter: (value, key, item) => {
					if(key.indexOf('Date') >= 0) {
						value = format.date({date: value, onlyDate: true})
					}
					else if(key == 'status') {
						value = status.issue.filter(el => el.value === value)[0]
					}
					return value;
				}
			}
		})
	},
	issueList: state => {
		return state.issueList.map(item => {
			item.status = {
				value: item.status,
				...status.issue.filter(el => el.value === item.status)[0]
			}
			return item;
		})
	},
	issueCount: state => state.issueCount,
	issueProjectList: state => {
		state.issueProjectList = state.issueProjectList.map(item => {
			return {
				text: `${item.code}: ${item.title}`,
				value: item.id
			}
		})
		return state.issueProjectList;
	},
	myProjectHeaders: state => {
		return tableHeaders.dashboardMyProjectTableHeader.map(header => {
			return {
				key: header.headerKey,
				label: header.text,
				isSort: header.isSort,
				sort: header.sort,
				link: header.link,
				formatter: (value, key, item) => {
					if(key.indexOf('Date') >= 0) {
						value = format.date({date: value, onlyDate: true})
					}
					else if(key == 'status') {
						value = { text: status.text[value], color: status.color[value]}
					}
					return value;
				}
			}
		})
	},
	myProjectList: state => state.myProjectList,
	myProjectCount: state => state.myProjectCount,
	myProjectChartsets: state => state.myProjectChartsets,
	myProjectStatus: state => state.myProjectStatus,
	deptProjectHeaders: state => {
		return tableHeaders.dashboardMyProjectTableHeader.map(header => {
			return {
				key: header.headerKey,
				label: header.text,
				isSort: header.isSort,
				sort: header.sort,
				link: header.link,
				formatter: (value, key, item) => {
					if(key.indexOf('Date') >= 0) {
						value = format.date({date: value, onlyDate: true})
					}
					else if(key == 'status') {
						value = { text: status.text[value], color: status.color[value]}
					}
					return value;
				}
			}
		})
	},
	deptProjectList: state => state.deptProjectList,
	deptProjectCount: state => state.deptProjectCount,
	deptProjectChartsets: state => state.deptProjectChartsets,
	deptProjectStatus: state => state.deptProjectStatus
}

const mutations = {
	setIssue: (state, data) => {
		state.issueList = [...data.list]
		state.issueCount = data.count;
	},
	setIssueProject: (state, data) => {
		state.issueProjectList = [...data.list]
		state.issueProjectCount = data.count;
	},
	setMyProject: (state, data) => {
		state.myProjectList = [...data.table.list]
		state.myProjectCount = data.table.count
		state.myProjectHeaders = [...data.table.header]
	},
	setMyProjectStatus: (state, data) => {
		let result = [];
		let keys = Object.keys(data);
		result.push({
			text: '전체',
			value: ''
		})
		keys.forEach((key) => {
			result.push({
				value: key,
				text: `${status.text[key]}(${data[key]})`
			})
		})
		state.myProjectStatus = result;
	},
	setMyProjectChartsets: (state, data) => {
		let statusKeys = Object.keys(data);
		let list = []
		statusKeys.forEach((key,index) => {
			list.push({
				label: status.text[key],
				data: data[key] ? [data[key]]: [],
				backgroundColor: status.project.filter(el => el.value === key)[0] ? status.project.filter(el => el.value === key)[0].backgroundColor : '#f6f6f6'
			})
		})
		list[0].borderSkipped = false;
		list[0].borderRadius = {
			topLeft: 5,
			bottomLeft: 5,
		}
		state.myProjectChartsets = {
			labels: [''],
			datasets: [...list],
		}
	},
	setDeptProject: (state, data) => {
		state.deptProjectList = [...data.table.list]
		state.deptProjectCount = data.table.count
		state.deptProjectHeaders = [...data.table.header]
	},
	setDeptProjectChartsets: (state, data) => {
		let statusKeys = Object.keys(data);
		let list = []
		statusKeys.forEach((key,index) => {
			list.push({
				label: status.text[key],
				data: data[key] ? [data[key]]: [],
				backgroundColor: status.project.filter(el => el.value === key)[0] ? status.project.filter(el => el.value === key)[0].backgroundColor : '#f6f6f6'
			})
		})
		list[0].borderSkipped = false;
		list[0].borderRadius = {
			topLeft: 5,
			bottomLeft: 5,
		}
		state.deptProjectChartsets = {
			labels: [''],
			datasets: [...list],
		}
	},
	setDeptProjectStatus: (state, data) => {
		let result = [];
		let keys = Object.keys(data);
		result.push({
			text: '전체',
			value: ''
		})
		keys.forEach((key) => {
			result.push({
				value: key,
				text: `${status.text[key]}(${data[key]})`
			})
		})
		state.deptProjectStatus = result;
	},
}

const actions = {
	//이슈보드 가져오기
	loadIssue: async ({commit, state}, params) => {
		const res = await api.dashboard.getIssue(params);
		commit('setIssue', res)
	},
	//이슈보드 업무 목록 가져오기
	loadIssueProject: async ({commit, state}, params) => {
		const res = await api.dashboard.getIssueProject(params);
		commit('setIssueProject', res)
	},
	addIssue: async({commit, dispatch}, form) => {
		const res = await api.dashboard.addIssue(form);
		dispatch('loadIssue')
		return res;
	},
	updateIssueStatus: async({commit, dispatch}, {id, status}) => {
		const res = await api.dashboard.updateIssueStatus(id, status);
		dispatch('loadIssue')
		return res;
	},
	//내 업무 리스트
	loadMyProject: async ({commit, state}, params) => {
		const res = await api.project.getList(params);
		commit('setMyProject', res)
	},
	//내 업무 상태별 현황
	loadMyProjectStatus: async ({commit, state}, params) => {
		const res = await api.project.getStatusSummary(params)
		commit('setMyProjectStatus', res)
		commit('setMyProjectChartsets', res)
	},
	//부서 내 업무 리스트
	loadDeptProject: async ({commit, state}, params) => {
		const res = await api.project.getList(params);
		commit('setDeptProject', res)
	},
	//부서 내 업무 상태별 현황
	loadDeptProjectStatus: async ({commit, state}, params) => {
		const res = await api.project.getStatusSummary(params)
		commit('setDeptProjectStatus', res)
		commit('setDeptProjectChartsets', res)
	},
}

export default { namespaced: true, state, getters, mutations, actions }