<template>
	<b-modal
		centered
		scrollable
		no-close-on-backdrop
		ref="modal"
		:size="size"
		:id="id"
		:title="title"
		:hide-header="hideHeader"
		:hide-footer="hideFooter"
		:ok-title="okTitle"
		:ok-variant="okVariant"
		:ok-disabled="okDisabled"
		:cancel-title="cancelTitle"
		cancel-variant="light"
		@ok="onSubmit"
		@cancel="$emit('cancel')"
		@show="$emit('show')"
		@shown="$emit('shown')"
		@hidden="$emit('hidden')"
	>
		<slot/>
		<slot name="custom-footer"/>
	</b-modal>
</template>

<script>
export default {
	name: 'ModalLayout',
	props: {
		title: {
			type: String,
			default: ''
		},
		size: {
			type: String,
			default: ''
		},
		hideHeader: {
			type: Boolean,
			default: false,
		},
		hideFooter: {
			type: Boolean,
			default: false,
		},
		id: {
			type: String,
			default: 'modal-layout'
		},
		okTitle: {
			type: String,
			default: '확인'
		},
		okVariant: {
			type: String,
			default: 'primary'
		},
		okDisabled: {
			type: Boolean,
			default: false,
		},	
		cancelTitle: {
			type: String,
			default: '취소'
		},
	},
	methods: {
		onSubmit(e) {
			e.preventDefault();
			this.$emit('submit', e)
		}
	}
}
</script>