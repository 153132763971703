const status = {
	fun: {
		"delete": "삭제",
		"edit": "수정",
		"add": "생성",
		"change": "변경",
		"input": "입력"
	},
	text: {
		"WORK_ACCEPTED": "접수완료",
		"WORK_REQUEST": "분석요청",
		"WORK_ANALYSIS": "시험분석",
		"WORK_DIRECTOR_REVIEW": "기술책임자 검토",
		"WORK_IMPLEMENTATION_REVIEW": "실장 검토",
		"WORK_APPROVAL": "승인",
		"WORK_COMPLETE": "완료",
		"WORK_CANCEL": "계약 취소",
		"ISSUE_ACCEPTED": "이슈 업",
		"ISSUE_COMPLETE": "처리완료",
		"CALCULATE_NOT_COMPLETE": "미완료",
		"CALCULATE_COMPLETE": "완료",
		"ANALYSIS_ING": "진행중",
		"ANALYSIS_COMPLETE": "완료",
		"DIRECTOR_WAIT": "대기",
		"DIRECTOR_APPROVAL": "승인",
		"DIRECTOR_REJECT": "반려",
		"SETTLE_PAID": "입금",
		"WAIT": "진행중",
		"COMPLETE": "분석완료",
		"SETTLE_TAX": "세금계산서 발급",
		"SETTLE_DISCOUNT": "감액",
		"SETTLE_INCREASE": "증액"
	},
	color: {
		"WORK_ACCEPTED": "orange",
		"WORK_REQUEST": "yellow",
		"WORK_ANALYSIS": "aquamarine",
		"WORK_DIRECTOR_REVIEW": "violet",
		"WORK_IMPLEMENTATION_REVIEW": "purple",
		"WORK_APPROVAL": "skyblue",
		"WORK_COMPLETE": "lightgreen",
		"WORK_CANCEL": "red",
		"ISSUE_ACCEPTED": "lightred",
		"ISSUE_COMPLETE": "brightgreen",
		"CALCULATE_NOT_COMPLETE": "red",
		"CALCULATE_COMPLETE": "lightgreen",
		"ANALYSIS_ING": "orange",
		"ANALYSIS_COMPLETE": "violet",
		"DIRECTOR_WAIT": "",
		"DIRECTOR_APPROVAL": "",
		"DIRECTOR_REJECT": "",
		"WAIT": "orange",
		"COMPLETE": "violet"
	},
	issue: [
		{ text: "이슈 업", value: "ISSUE_ACCEPTED", color: 'lightred', textColor: 'red' },
		{ text: "처리완료", value: "ISSUE_COMPLETE", color: 'brightgreen', textColor: 'green' } 
	],
	project: [
		{ text: '접수완료', value: 'WORK_ACCEPTED', color: 'orange', backgroundColor: '#FF922D' },
		{ text: '분석요청', value: 'WORK_REQUEST', color: 'yellow', backgroundColor: '#FEC741'},
		{ text: '시험분석', value: 'WORK_ANALYSIS', color: 'pcolor', backgroundColor: '#59C1B3' },
		{ text: '기술책임자 검토', value: 'WORK_DIRECTOR_REVIEW' , color: 'violet', backgroundColor: '#6C60CD'},
		{ text: '실장 검토', value: 'WORK_IMPLEMENTATION_REVIEW', color: 'purple', backgroundColor: '#BE4467' },
		{ text: '승인', value: 'WORK_APPROVAL', color: 'skyblue', backgroundColor: '#66BCEA' },
		{ text: '완료', value: 'WORK_COMPLETE', color: 'lightgreen', backgroundColor: '#6EBF60' }
	],
	approval: [
		{ text: '대기', value: 'WAIT', img: require('@/assets/img/needtoconclude.png')},
		{ text: '반려', value: 'REJECT', img: require('@/assets/img/notacceptable.png')},
		{ text: '승인', value: 'ACCEPT', img: require('@/assets/img/acceptable.png')},
	],
	settle: {
		"SETTLE_PAID": "입금",
		"SETTLE_TAX": "세금계산서 발급",
		"SETTLE_DISCOUNT": "감액",
		"SETTLE_INCREASE": "증액"
	},
	delivery: {
		"WAY_MAIL": "우편",
		"WAY_VISIT": "방문"
	},
	filter: {
		"client": "고객사",
		"department": "담당 부서",
		"isSettle": "정산",
		"researchDepartment": "시험 부서"
	},
	project_detail: {
		summary: {
			"status": "상태",
			"projectType": "업무 구분",
			"projectUser": "담당자",
			"projectDept": "시험 부서",
			"clientName": "고객사",
			"projectDueDate": "발급예정일",
		},
		detail: [
			{ name: '상태', key: 'status', type: 'status'  },
			{ name: '업무 구분', key: 'projectType' },
			{ name: '시료 수령 방법', key: 'projectSampleDeliveryWay' },
			{ name: '시료 채취 장소', key: 'projectAddress' },
			{ name: '용도', key: 'projectUseFor' },
			{ name: '의뢰일', key: 'projectRequestDate', type: 'date' },
			{ name: '발급 예정일', key: 'projectDueDate', type: 'date' },
			{ name: '담당 부서', key: 'projectDept' },
			{ name: '담당자', key: 'projectUser' },
			{ name: '시험부서', key: 'projectAnalysisDept' },
			{ name: '특이사항', key: 'projectDescription', type: 'memo' },
		],
		soil_detail: [
			{ name: '상태', key: 'status', type: 'status'  },
			{ name: '업무 구분', key: 'projectType' },
			{ name: '시료 수령 방법', key: 'projectSampleDeliveryWay' },
			{ name: '시료 채취 장소', key: 'projectAddress' },
			{ name: '되메움토발생지', key: 'projectBackfill' },
			{ name: '저장물질', key: 'projectStorageMaterial'},
			{ name: '용도', key: 'projectUseFor' },
			{ name: '의뢰일', key: 'projectRequestDate', type: 'date' },
			{ name: '발급 예정일', key: 'projectDueDate', type: 'date' },
			{ name: '담당 부서', key: 'projectDept' },
			{ name: '담당자', key: 'projectUser' },
			{ name: '시험부서', key: 'projectAnalysisDept' },
			{ name: '특이사항', key: 'projectDescription', type: 'memo' },
		],
		disposal_detail: [
			{ name: '상태', key: 'status', type: 'status'  },
			{ name: '업무 구분', key: 'projectType' },
			{ name: '시료 수령 방법', key: 'projectSampleDeliveryWay' },
			{ name: '시료 채취 장소', key: 'projectAddress' },
			{ name: '용도', key: 'projectUseFor' },
			{ name: '의뢰일', key: 'projectRequestDate', type: 'date' },
			{ name: '발급 예정일', key: 'projectDueDate', type: 'date' },
			{ name: '담당 부서', key: 'projectDept' },
			{ name: '담당자', key: 'projectUser' },
			{ name: '시험부서', key: 'projectAnalysisDept' },
			{ name: '배출자', key: 'projectDisposalName' },
			{ name: '배출자 연락처', key: 'projectDisposalContact'},
			{ name: '배출 장소', key: 'projectDisposalAddress' },
			{ name: '특이사항', key: 'projectDescription', type: 'memo' },
		],
		client: [
			{ name: '고객사 이름', key: 'clientName' },
			{ name: '고객사 ID', key: 'clientId' },
			{ name: '사업자등록번호', key: 'clientBizNum' },
			{ name: '대표자', key: 'clientCeoName' },
			{ name: '연락처', key: 'clientPhone' },
			{ name: '팩스번호', key: 'clientManagerFax' },
			{ name: '소재지', key: 'clientAddress' },
			{ name: '메모', key: 'clientMemo' },
			{ name: '담당자 이름', key: 'clientManagerName' },
			{ name: '담당자 연락처', key: 'clientManagerPhone' },
			{ name: '담당자 이메일', key: 'clientManagerEmail' },
		],
		sample: [
			{ name: '시료명(성적서 표기)', key: 'sampleName' },
			{ name: '시료 수령 방법', key: 'sampleTestMethod' },
			{ name: '시료 수', key: 'sampleCount', type: 'number' },
			{ name: '시료 채취자', key: 'sampleCollector' },
			{ name: '시료 채취 시작일', key: 'sampleCollectDate', type: 'date' },
			{ name: '시료 채취 종료일', key: 'sampleCollectEndDate', type: 'date' },
			{ name: '추가 시험 방법', key: 'sampleTestSecondMethod' },
			{ name: '시료상의 문제', key: 'sampleDescription' }
		],
		settle: {
			"paidAmount": "입금 금액",
			"unpaidAmount": "잔금",
			"paidDate": "입금 예정일",
			"taxDate": "세금계산서 발행일자"
		},
		settle_list: [
			{ name: '의뢰 금액 (VAT 포함)' , key: 'amount', type: 'price' },
			{ name: '입금 예정일', key: 'paidDate', type: 'date' },
			{ name: '세금계산서 발행처', key: 'settleClient' },
			{ name: '계산서 발행 일자', key: 'taxDate', type: 'date' },
			{ name: '회계 담당자', key: 'accountManager.name' },
			{ name: '회계 담당자 팩스번호', key: 'accountManager.fax' },
			{ name: '회계 담당자 연락처', key: 'accountManager.phone' },
			{ name: '회계 담당자 이메일', key: 'accountManager.email' }
		]
	}
}

export default status