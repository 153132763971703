<template>
	<b-dropdown class="options-btn" :class="isBadge ? 'status-btn' : 'list-btn'" :style="`background-color: var(--ehti-${color}); color: var(--ehti-${textColor})`" :dropright="dropright">
		<template #button-content>
			{{text}}
		</template>
		<b-dropdown-group v-if="options">
			<b-dropdown-item-button
				v-for="(option,index) in options"
				:key="`${option.value}${index}`"
				@click="onSelect(option)"
			>
				<b-badge v-if="isBadge" :class="option.color" :style="`background-color: var(--ehti-${option.color}); color: ${option.textColor ? `var(--ehti-${option.textColor})}` : `white`}`">{{option.text}}</b-badge>
				<p v-else>{{option.text}}</p>
			</b-dropdown-item-button>
		</b-dropdown-group>
	</b-dropdown>
</template>

<script>
export default {
	name: 'Badges',
	props: {
		text: {
			type: String,
			default: ''
		},
		color: {
			type: String,
			default: 'grey'
		},
		options: {
			type: Array,
			default: () => []
		},
		isBadge: {
			type: Boolean,
			default: false
		},
		dropright: {
			type: Boolean,
			default: false
		},
		textColor: {
			type: String,
			default: 'white'
		}
	},
	methods: {
		onSelect(option) {
			this.$emit('select', option)
		},
	}
}
</script>