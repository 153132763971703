import detail from './detail'
import history from './history'
import test from './test'
import settle from './settle'
import docx from './docx'
import list from './list'
import journal from './journal'

export default {
	namespaced: true,
	modules: {
		detail,
		history,
		test,
		settle,
		docx,
		list,
		journal
	},
}
