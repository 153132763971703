const menu = [
	{ 
		name: '대시보드',
		img: 'sidebar-menu1.png',
		path: '/',
		level: [1]
	},
	{
		name: '시험분석팀 대시보드',
		img: 'sidebar-menu1.png',
		path: '/dashboard/analysis',
		page: 'dashboard/analysis',
		level: [2]
	},
	{
		name: '관리자 대시보드',
		img: 'sidebar-menu1.png',
		path: '/dashboard/admin',
		level: [3]
	},
	{
		name: '고객사 관리',
		img: 'sidebar-menu2.png',
		path: '/client/list',
		page: 'client'
	},
	{
		name: '업무 관리',
		img: 'sidebar-menu3.png',
		path: '/project/list',
		page: 'project'
	},
	{
		name: '시험 분석',
		img: 'sidebar-menu4.png',
		path: '/analysis/list'
	},
	// {
	// 	name: '멤버/조직',
	// 	img: 'sidebar-menu5.png',
	// 	path: '/member/list',
	// 	page: 'member'
	// }
]

export {menu}