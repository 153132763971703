//정리
//최종농도에 비교해 표시농도가 나타나고, 특정 기준보다 작으면 불검출, 넘으면 최종농도를 그대로 보여줌. 최종농도의 경우 계산식에 따라 자동으로 계산됨
//특정 기준: avg로 지정
//각각 시료마다 계산식에 들어가야하는 배수가 다름. multiple 에 지정.
//특정 기준과 배수는 있을 수도, 없을수도 있음. 
//sc type: 
// 토양의 경우 : 1. 농도입력형(concentration), 2. 단순입력형(simple), 3. 소모량입력(consumption), 4. 산식(calculation), 5. 별도(etc), 
// 수질의 겨우 : 1. 농도입력형(concentration), 2. 단순입력형(simple), 3. 산식(calculation), 4. 별도(etc), 
// 대기의 경우 : 1. 시료량 입력(volume), 2.무게 입력형(weight), 3. 면적(area)
// 폐기물의 경우 : 1. 농도입력형(concentration), 2. 증발용기양 입력(evaporation)
//방식(method) type: 1. 직접 입력(direct), 2. 계산식 및 검정곡선식(std)
//표준물질(isStandard) type: 1. true, 2. false, 3. 시약(reagent), 4. 곡선식(curve)
//** 검정곡선식 : 검정곡선식 : Y = ax + b (x : 농도(mg/L), Y : 흡광도)
//표시 형태
// { category: '시험일지에서 사용할 시료이름', sc: 'SC', method: '방식', isStandard: '표준물질', isStd: 'STD 및 검정곡선', avg: '표시농도가 불검출인지, 검출농도로 나타나야 하는지에 대한 특정기준. 시료마다 각기 기준이 다름', multiple: '계산식에 들어갈 배수', formula: '계산식 function 으로 넣을 예정' }

const SOILFormula = [
	{
		//샘플 카테고리, 계산식 확인 용도
		category: 'SOIL_TEST_041',
		avg: 0.01,
		method: 'std', // 방식 : 직접 입력,
		// formula: (item) => {
		// 	//입력방법이 직접 입력일 경우에 단순계산식
		// 	//농도계산(mg/kg)=(시료농도-바탕시료농도) (mg/L) × 희석배율 × 시험용액부피 (mL)÷ 시료무게(g )× 함수율보정
		// 	return (Number(item.reagentConcentration) - Number(item.backgroundConcentration)) / Number(item.sampleCount) * Number(item.dilutionFactor)
		// },
		formula: (item, key) => {
			//입력방법이 검정곡선식일 경우에 곡선계산
			// Y = ax + b
			return item.reagentConcentration - (item.backgroundConcentration / item.sampleCount) * item.dilutionFactor
			item.lastConcentration = (item.a * item.reagentConcentration + item.b) * item.r2
		},
	},
	{
		category: '6가크롬',
		sc: 'concentration', //sc: 농도 입력형
		method: 'direct', // 방식 : 직접 입력,
		isStandard: true, //표준물질 // std 및 검정곡선,
		avg: 0.5,
		formula: (item, key) => {
			//농도계산(mg/kg)=(시료농도-바탕시료농도) (mg/L) × 희석배율 × 시험용액부피 (mL)÷ 시료무게(g )× 함수율보정
		},
	},
	{
		category: '벤조',
		sc: 'concentration', //sc: 농도 입력형
		method: 'direct', // 방식 : 직접 입력,
		isStandard: true, //표준물질 // std 및 검정곡선,
		avg: 0.005,
		formula: (item, key) => {
			//토양 중 농도 (mg/kg) = (시료 농도 (mg/L) - 바탕시료농도 (mg/L)) × 최종부피 (mL) ÷ (시료 무게 (g) × 함수율 보정) × 희석배수  
		}
	},
	{
		category: '불소',
		sc: 'concentration', //sc: 농도 입력형
		method: 'direct', // 방식 : 직접 입력,
		isStandard: true, //표준물질 // std 및 검정곡선
		formula: (item, key) => {
			//토양 중 농도 (mg/kg) = (시료 농도(mg/L) - 바탕시료 농도(mg/L)) × 최종부피(ml)  ÷ (시료 무게(g) × 함수율보정) × 희석배수
		}
	},
	{
		category: '시안',
		sc: 'concentration', //sc: 농도 입력형
		method: 'direct', // 방식 : 직접 입력,
		isStandard: true, //표준물질 // std 및 검정곡선,
		avg: 0.2,
		formula: (item, key) => {
			//토양 중 농도 (mg/kg) = (시료 농도(mg/L) - 바탕시료 농도(mg/L)) × 최종부피(ml)  ÷ (시료 무게(g) × 함수율보정) × 희석배수
		}
	},
	{
		category: '염농도',
		sc: 'simple', //sc: 단순 입력형
		method: 'direct', // 방식 : 직접 입력,
		isStandard: 'reagent', //표준물질 : 시약 // std 및 검정곡선,
		multiple: 0.064,
		formula: (item, key) => {
			//측정치(uS/cm) × (증류수(mL) ÷ 토양무게 (g)) * 온도보정계수 / 1000 * 0.064
		}
	},
	{
		category: '유기물함량',
		sc: 'consumption', //sc: 소모량 입력
		method: 'direct', // 방식 : 직접 입력,
		isStandard: 'reagent', //표준물질: 시약 // std 및 검정곡선,
		formula: (item, key) => {
			//유기물함량(g/kg) = (바탕 적정량(mL)-시료 적정량(mL)) × 적정액 농도보정계수 × 0.2 × 12 ÷ 4000 ×  100 ÷ 토양무게(g) × 1.724 × 10
		}
	},
	{
		category: '유기인',
		sc: 'calculation', //sc: 산식
		method: 'std', // 방식 : 계산식 및 검정곡선식,
		isStandard: 'curve', //표준물질 : 곡선식 // std 및 검정곡선,
		avg: 0.05,
		formula: (item, key) => {
			//토양 중 농도 (mg/kg) = ((측정된 농도(mg/L) - 바탕시료 농도(mg/L)) × 최종부피(mL) × 희석배수 ÷ ((시료무게(g) × 함수율보정값)
		}
	},
	{
		category: '유효규산',
		sc: 'calculation', //sc: 산식
		method: 'std', // 방식 : 계산식 및 검정곡선식,
		isStandard: 'curve', //표준물질 : 곡선식
		isStd: '', // std 및 검정곡선 (값이 없음!),
		multiple: 2.14,
		formula: (item, key) => {
			//일지에 값에 대한 설명은 없음 
			//값으로 되어있는 형태는 (시료농도 - 바탕시료농도) * (추출액량/ 시료량) * 희석배수 * 2.14
		}
	},
	{
		category: '유효인산',
		sc: 'calculation', //sc: 산식
		method: 'std', // 방식 : 검정곡선식,
		isStandard: true, //표준물질 // std 및 검정곡선,
		multiple: 2.29,
		formula: (item, key) => {
			//일지에 값에 대한 설명은 없음 
			//값으로 되어있는 형태는 (시료농도 - 바탕시료농도) * (추출액량/ 시료량) * 희석배수 * 2.29
		}
	},
	{
		category: '전기전도도',
		sc: 'concentration', //sc: 농도입력형
		method: 'std', // 방식 : 계산식 및 검정곡선식,
		isStandard: '', //표준물질(값이 없음!)
		isStd: '', // std 및 검정곡선(값이 없음!)
		formula: (item, key) => {
			//측정치(uS/cm) × (증류수(mL) ÷ 토양무게 (g)) * 온도보정계수 / 1000
		}
	},
	{
		type: 'group', // 시험항목이 여러 개인 곳에만 들어가는 타입. 기준이 각기 다르기 때문에 subCategoryList에 depth2 시험항목들 정리함.
		category: '중금속',
		sc: 'concentration', //sc: 농도입력형
		method: 'std', // 방식 : 계산식 및 검정곡선식,
		isStandard: true, //표준물질 // std 및 검정곡선
		formula: (item, key) => {
			// 토양 중 농도 (mg/kg) = (측정된 농도(mg/L) - 바탕시료 농도(mg/L)) × 최종부피(ml)  ÷ 시료 건조 중량(g) × 함수율 보정값 × 희석배수  
		},
		subCategory: [
			{
				name: 'As',
				avg: 1.5
			},
			{
				name: 'Zn',
				avg: 1.5
			},
			{
				name: 'Pb',
				avg: 1.5
			},
			{
				category: 'Cd',
				avg: 0.1
			},
			{
				category: 'Ni',
				avg: 1
			},
		]
	},
	{
		type: 'group', // 시험항목이 여러 개인 곳에만 들어가는 타입
		category: '수은',
		sc: 'concentration', //sc: 농도입력형
		method: 'std', // 방식 : 계산식 및 검정곡선식,
		isStandard: true, //표준물질 // std 및 검정곡선
		formula: (item, key) => {
			// 토양 중 농도 (mg/kg) = (측정된 농도(mg/L) - 바탕시료 농도(mg/L)) × 최종부피(ml)  ÷ 시료 건조 중량(g) × 함수율 보정값 × 희석배수  
		},
		subCategory: [
			{
				name: 'As',
				avg: 1.5
			},
			{
				name: 'Zn',
				avg: 1.5
			},
			{
				name: 'Pb',
				avg: 1.5
			},
			{
				category: 'Cd',
				avg: 0.1
			},
			{
				category: 'Ni',
				avg: 1
			},
		]
	},
	{
		category: '치환성양이온, CEC',
		sc: 'etc', //sc: 별도(etc)
		method: 'direct', // 방식 : 직접 입력,
		isStandard: true, //표준물질 // std 및 검정곡선,
		formula: (item, key) => {
			//Ca, K, Mg, Na (cmol/kg) = 치환성양이온의 실험에서 얻은 결과값
			//H (cmol/kg) = ( 7.00 - 측정된 pH ) x 22, 측정된 pH가 7이상이라면 0로 함.
		}
	},
	{
		category: '페놀류',
		sc: 'calculation', //sc: 산식
		method: 'std', // 방식 : 계산식 및 검정곡선식,
		isStandard: '', //표준물질(값이 없음!)
		isStd: '', // std 및 검정곡선(값이 없음!)
		formula: (item, key) => {
			// 검정곡선식 - 계산식 없음!
		}
	},
	{
		category: 'IC 이온류',
		sc: 'concentration', //sc: 농도 입력형
		method: 'direct', // 방식 : 직접 입력,
		isStandard: true, //표준물질 // std 및 검정곡선,
		avg: 0.1,
		formula: (item, key) => {
			// 농도계산(mg/kg) = ( 측정된농도(mg/L) × (최종량(mL)) ÷ (시료무게(g)) × (희석배율 )
		}
	},
	{
		category: 'PCBs',
		sc: 'std', //sc:  계산식 및 검정곡선식(std)
		method: 'calculation', // 방식 : 산식,
		isStandard: true, //표준물질 // std 및 검정곡선,
		avg: 0.05,
		formula: (item, key) => {
			// 토양 중 농도 (mg/kg) = ((측정된 농도(mg/L) - 바탕시료 농도(mg/L)) × 최종부피(mL) ÷ ((시료무게(g) × 함수율(%) ) x 희석배수
		}
	},
	{
		category: 'pH',
		sc: 'simple', //sc: 단순입력형
		method: 'direct', // 방식 : 직접 입력,
		isStandard: true, //표준물질
		isStd: '', // std 및 검정곡선 (값이 없음!),
		avg: 0.1,
		formula: (item, key) => {
			// 계산식 없음
		}
	},
	{
		category: 'T-P',
		sc: 'concentration', //sc: 농도 입력형
		method: 'std', // 방식 : 직접 입력
		isStandard: true, //표준물질 // std 및 검정곡선
		formula: (item, key) => {
			//농도계산 : 검량선에서 얻어진 시료농도(mg/L) - 바탕시료농도(mg/L)) ⅹ 희석배수 ⅹ 최종부피(mL) ÷ 시료량(g)
			let result = 0;
			if(item.reagentConcentration) {
				result = item.reagentConcentration - item.backgroundConcentration / item.sampleCount * item.dilutionFactor
			}
			return result
		},
		// formula: (item, key) => {
		// 	//입력방법이 검정곡선식일 경우에 곡선계산
		// 	// Y = ax + b
		// 	return ((item.a * item.reagentConcentration) + item.b) * item.r2
		// },
	},
	{
		category: 'TPH',
		sc: 'calculation', //sc: 산식
		method: 'std', // 방식 : 계산식 및 검정곡선식,
		isStandard: true, //표준물질 // std 및 검정곡선,
		avg: 0.1,
		formula: (item, key) => {
			// 계산식 없음
		}
	},
	{
		category: 'VOCs',
		sc: 'concentration', //sc: 농도 입력형
		method: 'direct', // 방식 : 직접 입력,
		isStandard: true, //표준물질 // std 및 검정곡선,
		avg: 0.1,
		formula: (item, key) => {
			// 토양 중 농도 (mg/kg) = (측정된 농도(mg/L) - 바탕시료 농도(mg/L) × 최종부피(mL) × 희석배수 ÷ (시료무게(g) × 함수율보정값)
		}
	},
]

const WATERFormula = [
	{ 
		type: 'group',
		category: 'VOCs', 
		sc: 'calculation',
		method: 'std',
		isStandard: true,
		subCategory: [
			{ 
				name: 'Vinyl chloride',
				avg: 0.005,
			},
			{ 
				name: '1,1-Dichloroethylene',
				avg: 0.005,
			},
			{ 
				name: 'Dichloromethane ',
				avg: 0.005,
			},
			{ 
				name: 'Acrylonitrile ',
				avg: 0.005,
			},
			{ 
				name: 'Chloroform',
				avg: 0.005,
			},
			{ 
				name: 'Carbon tetrachloride ',
				avg: 0.005,
			},
			{ 
				name: '1,2-Dichloroethane',
				avg: 0.005,
			},
			{ 
				name: 'Benzene ',
				avg: 0.005,
			},
			{ 
				name: 'Trichloroethylene (TCE)',
				avg: 0.005,
			},
			{ 
				name: 'Toluene',
				avg: 0.005,
			},
			{ 
				name: 'Tetrachloroethylene (PCE)',
				avg: 0.005,
			},
			{ 
				name: 'm,p-xylene',
				avg: 0.005,
			},
			{ 
				name: 'o-xylene ',
				avg: 0.005,
			},
			{ 
				name: 'Bromoform',
				avg: 0.005,
			},
			{ 
				name: 'Naphtalene',
				avg: 0.003,
			},
			{ 
				name: 'Styrene',
				avg: 0.006,
			},
		]
	},
	{ 
		category: '포름', 
		sc: 'calculation',
		method: '포름',
		isStandard: true,
		avg: 0.01,
		formula: (item, key) => {
			//검정곡선식
		}
	},
	{ 
		category: '페놀', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.005,
		formula: (item, key) => {
			// (시료농도-바탕시료농도) × 희석배율
		}
	},
	{ 
		category: '펜타', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.001,
	},
	{ 
		category: '1,4-다이옥산', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.01,
	},
	{ 
		category: 'EPI', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.003,
	},
	{ 
		category: 'Acry', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.005,
	},
	{ 
		category: 'DEHP', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.0025,
	},
	{ 
		category: 'DEHA', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.0025,
	},
	{ 
		category: '유기인', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.0005,
	},
	{ 
		category: 'PCBs', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.0005,
	},
	{ 
		type: 'group',
		category: '중금속(일반)', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		subCategory: [
			{ 
				name: 'Ni',
				avg: 0.015,
			},
			{ 
				name: 'Cu',
				avg: 0.006,
			},
			{ 
				name: 'Cd',
				avg: 0.004,
			},
			{ 
				name: 'Pb',
				avg: 0.04,
			},
			{ 
				name: 'Zn',
				avg: 0.002,
			},
			{ 
				name: 'Cr',
				avg: 0.007,
			},
			{ 
				name: 'As',
				avg: 0.05
			},
		]
	},
	{ 
		category: 'Hq', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.0005
	},
	{ 
		category: 'Se', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.03
	},
	{ 
		category: 'Fe,Mn', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.002
	},
	{ 
		category: 'Sb,Ba', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.003
	},
	{ 
		category: 'BOD', 
		sc: 'etc',
		method: 'direct',
		isStandard: false,
	},
	{ 
		category: 'COD', 
		sc: 'etc',
		method: 'direct',
		isStandard: false,
	},
	{ 
		category: 'TOC', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.03,
	},
	{ 
		category: 'ABS', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.02,
	},
	{ 
		category: 'T-N', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.1,
	},
	{ 
		category: 'T-P', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.005,
	},
	{ 
		category: 'SS', 
		sc: 'etc',
		method: 'direct',
		isStandard: false,
		avg: 0.005,
	},
	{ 
		category: '음이온', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.1,
	},
	{ 
		category: '퍼클', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.002,
	},
	{ 
		category: '시안', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.01,
	},
	{ 
		category: '페놀류', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.001,
	},
	{ 
		category: '6가크롬', 
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.01,
	},
	{ 
		category: 'n-H', 
		sc: 'etc',
		method: 'direct',
		isStandard: false,
		avg: 0.5,
		formula: (item, key) => {
			//광유류(mg/L) = (유출액중노말헥산추출물질의무게차(mg) - 바탕시험에의한잔류물의무게차(mg)) x 2 x 1000 / 시료량 동 · 식물유지류(mg/L) = 총노말헥산추출물질의양(mg/L) - 광유류의양(mg/L)
		}
	},
	{ 
		category: '색도', 
		sc: 'calculation',
		method: '색도',
		isStandard: true,
		formula: (item, key) => {
			//평균보정계수*색차값/셀길이
			//TODO!! 이부분은 너무 복잡해 다시 한 번 이해가 필요함
		}
	},
	{ 
		category: '총대장균', 
		sc: 'simple',
		method: 'direct',
		isStandard: false,
		formula: (item, key) => {
			// 집락수 30개 ~ 300개의 범위에서 산술평균하여  소수점을 포함하는 경우 반올림하여 정수로 표기
		}
	},
	{ 
		category: 'ph', 
		sc: 'simple',
		method: 'direct',
		isStandard: false,
	},
	{ 
		category: '온도', 
		sc: 'simple',
		method: 'direct',
		isStandard: false,
	},
]
const AIRFormula = [
	{ 
		category: '먼지',
		sc: 'weight',
		method: 'direct',
		isStandard: true,
		formula: (item, key) => {
			//농도계산식 : 채취된 먼지량 / 건조시료가스채취량
		}
	},
	{ 
		category: '이황화탄소',
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 1.3,
		formula: (item, key) => {
			//농도계산식 : (((시료농도 - Blank 농도) * 최종량)/건조시료가스채취량)*1000
		}
	},
	{ 
		category: '암모니아',
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.4,
		formula: (item, key) => {
			//농도계산식 : (시료농도 - Blank 농도) *25 / 건조시료가스채취량
		}
	},
	{ 
		category: '황화수소',
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.5,
		formula: (item, key) => {
			//농도계산식 : (시료농도 - Blank 농도) *10 / 건조시료가스채취량 * 22.4 / 32.06
		}
	},
	{ 
		category: '브룸',
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.6,
		formula: (item, key) => {
			//농도계산식 : (시료농도 - Blank 농도) * 최종량 / 분취량 / 건조시료가스 채취량 * 0.28 * 1000
		}
	},
	{ 
		category: '시안',
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.02,
		formula: (item, key) => {
			//농도계산식 : (시료농도 - Blank 농도) * 10 / 건조시료가스 채취량 * 22.4 / 26.017
		}
	},
	{ 
		category: '불소',
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.02,
		formula: (item, key) => {
			//농도계산식 : (시료농도 - Blank 농도) * 10 / 희석배수 * 22.4 / 18.998
		}
	},
	{ 
		category: '페놀',
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.32,
		formula: (item, key) => {
			//농도계산식 : (시료농도 - Blank 농도) * 최종량 / 분취량 / 건조시료가스 채취량
		}
	},
	{ 
		category: '알데하이드류',
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.003,
		formula: (item, key) => {
			//농도계산식 : ((시료농도 - Blank 농도) * 최종량 / 건조시료가스 채취량 * (22.4/30.03)) *0.1429 * 희석배수
		}
	},
	{ 
		category: '히드라진',
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.02,
		formula: (item, key) => {
			//농도계산식 : ((시료농도 - Blank 농도) * 최종량 / 건조시료가스 채취량 * (22.4/30.03)) *0.1429 * 희석배수
		}
	},
	{ 
		category: '염화수소',
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.1,
		formula: (item, key) => {
			//농도계산식 : ((시료농도 - Blank 농도) * 최종량 / 건조시료가스 채취량 * (22.4/30.03)) *0.1429 * 희석배수
		}
	},
	{ 
		type: 'group',
		category: '중금속',
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		subCategory: [
			{ 
				name: 'Ni',
				avg: 0.002,
				isEqual: true,
			},
			{ 
				name: 'Zn',
				avg: 0.016,
				isEqual: true,
			},
			{ 
				name: 'Cu',
				avg: 0.016,
				isEqual: true,
			},
			{ 
				name: 'Cd',
				avg: 0.002,
				isEqual: true,
			},
			{ 
				name: 'Pb',
				avg: 0.008,
				isEqual: true,
			},
			{ 
				name: 'Cr',
				avg: 0.016,
				isEqual: true,
			},
		]
	},
	{ 
		category: '비소',
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.001,
		formula: (item, key) => {
			//농도계산식 : =((((((AM113-BI113)*CE113*0.001)/CS81)*1000*DW113)+((((AM114-BI114)*CE114*0.001)/CS93)*1000*DW114))*22.41/74.92)
			//농도계산식 : (((시료농도 - Blank 농도) * 최종량 * 0.001 / 건조시료가스 채취량) * 1000 * 희석배수)) + (((시료농도 두번째 - Blank 농도 두번째) * 최종량 두번째 * 0.001 / 건조시료가스 채취량) * 1000 * 희석배수)) * 22.41 / 74.92
		}
	},
	{ 
		category: '수은',
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.0002,
		formula: (item, key) => {
			//농도계산식 : =(((((AM92-BI92)*CE92*0.001)/CS77)*DW92)+((((AM93-BI93)*CE93*0.001)/CS77)*DW93))
			//농도계산식 : (((시료농도 - Blank 농도) * 최종량 * 0.001 / 건조시료가스 채취량) * 희석배수)) + (((시료농도 두번째 - Blank 농도 두번째) * 최종량 두번째 / 건조시료가스 채취량) * 희석배수)
		}
	},
	{ 
		type: 'group',
		category: 'Fe,Mn,Al',
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		formula: (item, key) => {
			//농도계산식 : ((시료농도 - Blank 농도) * 최종량 * 0.001 / 건조시료가스 채취량
		},
		subCategory: [
			{ 
				name: 'Fe',
				avg: 0.001,
				isEqual: true,
			},
			{
				name: 'Mn',
				avg: 0.001,
				isEqual: true,
			},
			{
				name: 'Al',
				avg: 0.002,
				isEqual: true,
			},
		]
	},
	{ 
		type: 'group',
		category: 'Be,Te,V',
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		formula: (item, key) => {
			//농도계산식 : ((시료농도 - Blank 농도) * 최종량 * 0.001 / 건조시료가스 채취량
		},
		subCategory: [
			{ 
				name: 'Be',
				avg: 0.008,
				isEqual: true,
			},
			{
				name: 'Te',
				avg: 0.001,
				isEqual: true,
			},
			{
				name: 'V',
				avg: 0.001,
				isEqual: true,
			},
		]
	},
	{ 
		category: 'Se,Sb,S,B,Ba',
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		formula: (item, key) => {
			//농도계산식 : ((시료농도 - Blank 농도) * 최종량 * 0.001 / 건조시료가스 채취량
		},
		subCategory: [
			{ 
				name: 'Se',
				avg: 0.002,
				isEqual: true,
			},
			{
				name: 'Sb',
				avg: 0.001,
				isEqual: true,
			},
			{
				name: 'S',
				avg: 0.002,
				isEqual: true,
			},
			{
				name: 'B',
				avg: 0.001,
				isEqual: true,
			},
			{
				name: 'Ba',
				avg: 0.001,
				isEqual: true,
			},
		]
	},
	{ 
		category: '인',
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.001,
		formula: (item, key) => {
			//농도계산식 : ((시료농도 - Blank 농도) * 최종량 * 0.001 / 건조시료가스 채취량
		},
	},
	{ 
		category: '이황화메틸',
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		avg: 0.03,
		formula: (item, key) => {
			//농도계산식 : ((시료농도 - Blank 농도) * 최종부피 / 시료부피
		},
	},
	{ 
		category: '벤조(a)피렌',
		sc: 'area',
		method: 'direct',
		isStandard: true,
		avg: 10.3168,
		formula: (item, key) => {
			//농도계산식 : =DA86*2000/$AE$58/CS71
			//농도계산식 : 시료면적비 * 2000 / RRF 평균 / 건조시료가스 채취량
		},
	},
	{ 
		type: 'group',
		category: 'VOCs',
		sc: 'volume',
		method: 'direct',
		isStandard: true,
		formula: (item, key) => {
			//농도계산식 : (시료량 - 바탕시료량)/건조시료가스채취량 * 22.4/ 카테고리마다 곱하는 양이 다름
		},
		subCategory: [
			{
				name: 'Acrylonitrile',
				avg: 0.03,
				isEqual: true,
				multiple: 53.06,
			},
			{
				name: 'Dichloromethane',
				avg: 0.17,
				isEqual: true,
				multiple: 84.94,
			},
			{
				name: 'Chloroform',
				avg: 0.03,
				isEqual: true,
				multiple: 119.38,
			},
			{
				name: '1,2-Dichloroethane',
				avg: 0.03,
				isEqual: true,
				multiple: 98.96,
			},
			{
				name: 'Benzene',
				avg: 0.03,
				isEqual: true,
				multiple: 78.12,
			},
			{
				name: 'Carbon tetrachloride',
				avg: 0.03,
				isEqual: true,
				multiple: 153.82,
			},
			{
				name: 'TCE',
				avg: 0.1,
				isEqual: true,
				multiple: 131.29,
			},
			{
				name: 'PCE',
				avg: 0.03,
				isEqual: true,
				multiple: 165.83,
			},
			{
				name: 'Ethylbenzene',
				avg: 0.03,
				isEqual: true,
				multiple: 106.17,
			},
			{
				name: 'Styrene',
				avg: 0.03,
				isEqual: true,
				multiple: 104.16,
			},
			{
				name: 'Aniline',
				avg: 0.03,
				isEqual: true,
				multiple: 93.13,
			},
		]
	},
	{ 
		type: 'group',
		category: '1-3뷰타다이엔, 염화바이닐',
		sc: 'volume',
		method: 'direct',
		isStandard: true,
		formula: (item, key) => {
			//농도계산식 : (시료량 - 바탕시료량)/건조시료가스채취량 * 22.4/ 카테고리마다 곱하는 양이 다름
		},
		subCategory: [
			{
				name: 'Vynyl chloride',
				avg: 0.01,
				multiple: 62.5,
			},
			{
				name: '1,3-Butadiene',
				avg: 0.03,
				multiple: 54.09
			},
		]
	},
	{ 
		type: 'group',
		category: '옥사이드류',
		sc: 'concentration',
		method: 'direct',
		isStandard: true,
		formula: (item, key) => {
			//농도계산식 : (시료농도 - 바탕시료농도) * 표준가스 주입량 / 시료가스 주입량
		},
		subCategory: [
			{
				name: '에틸렌옥사이드',
				avg: 0.03,
			},
			{
				name: '프로필렌옥사이드',
				avg: 0.03,
			},
		]
	},
]

const DISPOSALFormula = [
	{
		category: 'VOCs (HS)',
		sc: 'concentration',
		isStandard: true,
	},
	{
		category: 'VOCs (FT)',
		sc: 'concentration',
		isStandard: true,
	},
	{
		category: '유기인',
		sc: 'concentration',
		isStandard: true,
	},
	{
		category: '중금속(일반)',
		sc: 'concentration',
		isStandard: true,
	},
	{
		category: 'Hg',
		sc: 'concentration',
		isStandard: true,
	},
	{
		category: '시안',
		sc: 'concentration',
		isStandard: true,
	},
	{
		category: '6가크롬',
		sc: 'concentration',
		isStandard: true,
	},
	{
		category: '수분 및 고형물',
		sc: 'evaporation',
		isStandard: false,
	},
	{
		category: '강열감량 및 유기물 함량',
		sc: 'evaporation',
		isStandard: false,
	},
	{
		category: '기름성분',
		sc: 'evaporation',
		isStandard: true,
	},
	{
		category: 'Ph',
		sc: 'concentration',
		isStandard: true,
	},
	{
		category: '할로겐화유기물질',
		sc: 'concentration',
		isStandard: true,
	},
]

export {SOILFormula, WATERFormula, AIRFormula, DISPOSALFormula}
