import api from '@/api'
import status from '@/utils/status'
import format from '@/utils/format'
import tableHeaders from '@/utils/tableHeaders'

const state = {
	sampleInfo: {
		
	},
	sampleList: [],
	viewSpecimen: {
		headers: [],
		list: [],
	},
	viewCategory: {
		headers: [],
		list: []
	},
	testList: [
		{
			type: '',
			testList: []
		}
	],
	sampleDetail: [],
	//시료 입력
	sampleForm: {
		sampleName: "",
		sampleDeliveryWay: "",
		sampleTestMethod: "",
		sampleCount: 0,
		sampleCollector: "",
		sampleCollectDate: "",
		sampleDescription: "",
		sampleContainer: '',
		sampleVolume: '',
		testDetail: {
			list: []
		},
		testList: [],
		updateDatetime: '',
		sampleExpire: ''
	},
	testCategory: {
		list: [],
		type: ''
	}
}

const getters = {
	sampleInfo: state => state.sampleInfo,
	sampleList: state => state.sampleList,
	testList: state => state.testList,
	sampleForm: state => state.sampleForm,
	sampleDetail: state => {
		let list = []
		status.project_detail.sample.forEach(item => {
			list.push({
				key: item.key,
				name: item.name,
				value: format.dataValue(item.key, state.sampleDetail),
				type: item.type
			})
		})
		return list;
	},
	testCategory: state => state.testCategory,
	viewSpecimen: state => {
		state.viewSpecimen.headers = state.viewSpecimen.headers.map(header => {
			header.key = header.testId
			return header
		})
		return state.viewSpecimen
	},
	viewCategory: state => {
		state.viewCategory.headers = state.viewCategory.headers.map(header => {
			header.key = header.headerKey
			header.label = header.text ? header.text: header.name
			header.formatter = (value, key, item) => {
				return format.dataValue(key, item)
			}
			return header
		})
		return state.viewCategory
	}
}

const mutations = {
	setSampleInfo: (state, data) => {
		state.sampleInfo = {...data}
		state.sampleDetail = {...data}
	},
	setSampleList: (state, data) => {
		state.viewSpecimen.headers = [...tableHeaders.testInfoTableHeaders.map(header => {
			header.key = header.headerKey
			header.label = header.text
			return header
		}), ...state.testCategory.list.map(header => {
			header.key = header.id
			header.label = header.name
			header.original_name = header.original_name
			header.name = header.name
			return header
		})]
		state.viewSpecimen.list = [...data]
		state.viewCategory.headers = tableHeaders.categoryTestInfoTableHeaders
		state.viewCategory.list = [...data]
		state.sampleList = [...data]
	},
	setTest: (state, data) => {
		state.testList = [...data]
	},
	setTestCategory: (state, data) => {
		state.testCategory = {...data}
	},
	resetForm: (state) => {
		let result = {...state.form}
		let keys = Object.keys(result)
		keys.forEach(key => {
			result[key] = ''
			if(typeof result === 'object') {
				result[key].list = []
			}
		})
		state.sampleInfo = result;
		state.form = result;
	}
}

const actions = {
	//시료 정보 가져오기
	loadSampleInfo: async ({state, commit}, id) => {
		const res = await api.project.getSample(id)
		commit('setSampleInfo', res)
	},
	//시료 리스트 가져오기
	loadSampleList: async ({commit, dispatch}, payload) => {
		const {id, isGroup} = payload
		const res = await api.project.getSampleList(id, isGroup)
		commit('setSampleList', res)
	},
	//시료 생성 & 수정하기
	updateSample: async({commit}, payload) => {
		const {id, body} = payload
		const res = await api.project.updateSample(id, body)
		return res
	},
	//시험 정보 가져오기
	loadTest: async ({state, commit}, id) => {
		const res = await api.project.getTest(id, state.params)
		commit('setTest', res)
	},
	//시험 정보 입력 (등록, 수정)
	updateTest: async ({commit, dispatch}, payload) => {
		const {id, body} = payload;
		const res = await api.project.updateTest(id, body)
		return res
	},
	loadTestCategory: async ({state, commit}, id) => {
		const res = await api.project.getCategory(id)
		commit('setTestCategory', res)
	}
}

export default { namespaced: true, state, getters, mutations, actions }