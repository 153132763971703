//global component
import Vue from 'vue'
import EtpsBtn from '@/components/button'
import CommonTable from '@/components/table'
import PageHeader from '@/components/page/PageHeader'
import Card from '@/components/info/card'
import ModalLayout from '@/components/modal'
import Fields from "@/components/form/fields.vue";

Vue.component('e-btn', EtpsBtn)
Vue.component('common-table', CommonTable)
Vue.component('page-header', PageHeader)
Vue.component('e-card', Card)
Vue.component('modal-layout', ModalLayout)
Vue.component('fields', Fields)