import Vue from 'vue'
import Vuex from 'vuex'

import client from './client'
import project from './project'
import analysis from './analysis'
import user from './user'
import dashboard from './dashboard'

Vue.use(Vuex)

const store = new Vuex.Store({
	namespaced: true,
	modules: {
		client,
		project,
		analysis,
		user,
		dashboard
	}
})

export default store
