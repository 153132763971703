import api from '@/api'

import tableHeaders from '@/utils/tableHeaders'
import format from '@/utils/format'
import { AIRFormula, DISPOSALFormula, SOILFormula, WATERFormula } from "@/utils/journal";
const state = {
	referral: {
		headers: [],
		list: [],
		count: 1,
	},
	journal: {
		headers: [],
		list: [],
		count: 1,
	},
	journalResult: [],
	journalList: [],
	journalForm: {
		testDate: '',
		machine : {
			name: '',
			data: []
		},
		testMachineCondition: [],
		testMethod: '',
		method: [],
	},
	testMachineHeaders: [],
	machineList: [],
	journalData: {},
	report: {
		headers: [],
		list: [],
	},
	testResult: {
		approvalDatetime: null,
		endDate: null,
		humidityMax: null,
		humidityMin: null,
		rejectDatetime: "",
		requestDate: "",
		startDate: "",
		status : "",
		temperatureMax : "",
		temperatureMin : "",
		url : null,
		headers: [],
		list: []
	},
	environment: {
		temperature: '',
		humidity: '',
		note: ''
	},
	sampleResultList: [],
	document: {
		headers: [],
		list: [],
	}
}

const getters = {
	referral: state => {
		state.referral.headers = state.referral.headers.map(header => {
			header.key = header.headerKey
			header.label = header.text
			header.formatter = (value, key, item) => {
				//date format
				if(key.indexOf('Date') >= 0) {
					value = format.date({date: value, onlyDate: true})
				}
				return value;
			}
			return header
		})
		return state.referral
	},
	journal: state => {
		state.journal.headers = state.journal.headers.map(header => {
			header.key = header.headerKey
			header.label = header.text
			header.formatter = (value, key, item) => {
				//date format
				if(key.indexOf('Date') >= 0) {
					value = format.date({date: value, onlyDate: true})
				}
				return value;
			}
			return header
		})
		return state.journal
	},
	journalList: state => {
		return state.journalList.map(item => {
			item.text = `${item.testName.name}(${item.projectCode})`
			item.value = item.testId
			return item
		})
	},
	journalResult: state => state.journalResult,
	journalForm: state => state.journalForm,
	machineList: state => {
		return state.machineList.map(item => {
			return {
				text: item.name,
				value: item.data
			}
		})
	},
	testMachineHeaders: state => {
		return tableHeaders.useDeviceTableHeader.map(header => {
			header.key = header.headerKey
			header.label = header.text
			return header
		})
	},
	journalData: state => state.journalData,
	report: state => {
		state.report.headers = state.report.headers.map(header => {
			header.key = header.headerKey
			header.label = header.text
			header.formatter = (value, key, item) => {
				//date format
				if(key.indexOf('Date') >= 0) {
					value = format.date({date: value, onlyDate: true})
				}
				return value;
			}
			return header
		})
		return state.report
	},
	testResult: state => {
		state.testResult.headers = state.testResult.headers.map(header => {
			header.key = header.headerKey
			header.label = header.text
			return header;
		})
		return state.testResult
	},
	environment: state => state.environment,
	sampleResultList: state => state.sampleResultList,
	document: state => {
		state.document.headers = state.document.headers.map(header => {
			header.key = header.headerKey
			header.label = header.text
			header.formatter = (value, key, item) => {
				//date format
				if(key.indexOf('Date') >= 0) {
					value = format.date({date: value, onlyDate: true})
				}
				return value;
			}
			return header
		})
		return state.document
	}
}

const mutations = {
	setEnvironment: (state, data) => {
		state.environment = {...state.environment, ...data}
	},
	setReferral: (state, data) => {
		state.referral.headers = [...tableHeaders.testRequestTableHeader]
		state.referral.list = [...data.res.list]
		state.referral.list = state.referral.list.map(el => {
			el.url = `/v1/project/${data.id}/referral/${el.id}`
			return el
		})
		state.referral.count = data.res.list.length
	},
	setJournalList: (state, data) => {
		// state.journalList = [...data]
	},
	setJournalResult: (state, data) => {
		state.journalResult = {...data}
	},
	setJournalForm: (state, data) => {
		state.journalForm = {...state.journalForm, ...data}
		state.journalForm.testMethod = state.journalForm.testMethod ? state.journalForm.testMethod : state.journalForm.method[0]
		state.journalForm.form_data[0].forEach(form => {
			let obj = {};
			form.form.map(item => {
				obj[item.headerKey] = ''
				item.key = item.headerKey
				item.label = item.text
				item.readonly = (item.headerKey === 'lastConcentration' || item.headerKey === 'markedConcentration')
				return item
			})
			if(form.key === 'useReagent' && state.journalForm.reagents) {
				state.journalForm['useReagent'] = [...state.journalForm.reagents]
			}
		})
		state.journalForm.form_data[1].forEach(form => {
			let obj = {};
			form.form.map(item => {
				obj[item.headerKey] = ''
				item.key = item.headerKey
				item.label = item.text
				item.readonly = (item.headerKey === 'lastConcentration' || item.headerKey === 'markedConcentration')
				return item
			})
		})
	},
	resetJournalForm: (state) => {
		state.journalForm = {
			testDate: '',
			machine : {
				name: '',
				data: []
			},
			testMachineCondition: [],
			testMethod: '',
			method: [],
		}
		state.environment = {
			temperature: '',
			humidity: '',
			note: ''
		}
	},
	setMachine: (state, data) => {
		state.machineList = [...data]
	},
	setMatchJournalData: (state, data) => {
		let list = [];
		if(data.test_id) {
			//데이터를 가져오는 조건은 변경될 것 같음.
			if(data.test_id.indexOf('SOIL') >= 0) { 
				//타입 : 토양일 경우
				list = SOILFormula
			} else if(data.test_id.indexOf('WATER') >= 0) {
				//타입 : 수질일 경우
				list = WATERFormula
			} else if(data.test_id.indexOf('AIR') >= 0) {
				//타입 : 대기일 경우
				list = AIRFormula
			} else if(data.test_id.indexOf('DISPOSAL') >= 0) {
				//타입 : 폐기물일 경우
				list = DISPOSALFormula
			}
			
			let result = list.filter(el => el.category === data.name)[0];
			state.journalData = result ?? {}
		}
		state.journalData = {...state.journalData}
	},
	setReport: (state, data) => {
		state.report.headers = [...tableHeaders.testResultTableHeader]
		state.report.list = [...data.list]
		//다운로드 링크 추가
		state.report.list = state.report.list.map(el => {
			el.url = `/v1/project/test/report/${el.id}`
			return el
		})
	},
	setTestResult: (state,data) => {
		state.testResult.headers = [
			{
				headerKey: 'testName',
				text: '시료 이름'
			},
			{
				headerKey: 'testResultUnit',
				text: '단위'
			},
			{
				headerKey: 'sampleList',
				text: '검체명',
				form: []
			},
		]
		state.testResult.list = [];
		if(data.length > 0) {
			let nameIdx = 0;
			data[0].sampleList.forEach((item,idx) => {
				nameIdx = idx;
				state.testResult.headers[2].form.push({
					text: `${item.name}`,
					headerKey: `sampleItem${idx}`
				})
			})
			data.forEach((item, idx) => {
				state.testResult.list.push({
					key: `sampleItem${nameIdx}`,
					...item,
				})
			})
		}
	},
	setTestResultInfo: (state, data) => {
		state.testResult = {...state.testResult, ...data}
	},
	setSampleResultList: (state, data) => {
		state.sampleResultList = [...state.sampleResultList, ...data]
	},
	setDocument: (state, data) => {
		state.document.headers = [...tableHeaders.documentTableHeader]
		state.document.list = [...data]
	}
}

const actions = {
	//산출문서 - 시험의뢰서 가져오기
	loadReferral: async ({state, commit}, id) => {
		const res = await api.project.getReferral(id)
		commit('setReferral', {res: res, id: id})
	},
	//산출문서 - 시험의뢰서 개별 다운로드
	downloadReferral: async ({state, commit}, payload) => {
		const {projectId, id} = payload
		const res = await api.project.downloadReferral(projectId, id)
		return res;
	},
	//산출문서 - 시험의뢰서 생성하기
	addReferral: async ({commit, dispatch}, id) => {
		const res = await api.project.createReferral(id)
		dispatch('loadReferral', id)
		return res;
	},
	//산출문서 - 시험의뢰서 개별 삭제하기
	deleteReferral: async({commit, dispatch}, payload) => {
		const {projectId, id} = payload
		const res = await api.project.deleteReferral(projectId, id)
		dispatch('loadReferral', projectId)
		return res;
	},
	//시험일지 - 시험일지 목록 가져오기
	loadJournalList: async({state, commit}, id) => {
		const res = await api.project.getTestJournal(id)
		commit('setJournalList', res)
	},
	//시험일지 - 입력된 시험 결과 가져오기
	loadJournalResult: async({state,commit}, payload) => {
		const {id, testId, name} = payload
		const res = await api.project.getResult(id, testId)
		commit('setJournalResult', res)
		commit('setMatchJournalData', {...res, name: name})
	},
	//시험일지 - 시험결과 저장하기
	updateJournalResult: async({state, commit}, payload) => {
		const {projectId, testId, body} = payload;
		try {
			const res = await api.project.updateJournalResult(projectId, testId, body)
			commit('resetJournalForm')
			return res
		} catch(e) {
			return false
		}
	},
	//시험일지 - 일지 form data 가져오기
	loadJournalForm: async ({state, commit}, id) => {
		const res = await api.project.getJournalForm(id)
		commit('setJournalForm', res)
	},
	//시험일지 - 기기리스트 가져오기
	loadMachine: async({state, commit}) => {
		const res = await api.project.getMachine()
		commit('setMachine', res)
	},
	//시험일지 - 입력해야하는 시료 목록 가져오기
	loadSampleResultList: async({state, commit}, payload) => {
		const {projectId, testId} = payload
		const res = await api.project.getSampleResultList(projectId, testId)
		commit('setSampleResultList', res)
	},
	loadReport: async({state, commit}, projectId) => {
		const res = await api.project.getReport(projectId)
		commit('setReport', res)
	},
	//산출문서 - 시험성적서
	loadDocument: async({state, commit}, projectId) => {
		const res = await api.project.getDocument(projectId)
		commit('setDocument', res)
	},
	reportDocument: async({commit, dispatch}, {id, payload, mode}) => {
		const res = await api.project.reportDocument(id, payload, mode)
		dispatch('loadDocument', id)
		return res;
	},
	deleteDocument: async({state, dispatch}, {projectId, documentId}) => {
		const res = await api.project.deleteDocument(documentId)
		dispatch('loadDocument', projectId)
		return res;
	},
	downloadReport: async({state, commit}, id) => {
		const res = await api.project.downloadReport(id)
	},
	deleteReport: async({commit, dispatch}, payload) => {
		const {projectId, reportId} = payload
		const res = await api.project.deleteReport(reportId)
		dispatch('loadReport', projectId)
		return res;
	},
	//산출문서 - 시험결과 확인하기 가져오기
	loadTestResult: async({commit}, id) => {
		const res = await api.project.getTestResult(id);
		const info = await api.project.getTestResultInfo(id);
		commit('setTestResult', res)
		commit('setTestResultInfo', info)
	},
	//산출문서 - 시험결과 정보 가져오기
	loadTestResultInfo: async({commit}, id) => {
		const res = await api.project.getTestResultInfo(id);
		commit('setTestResultInfo', res)
	},
	//산출문서 - 시험결과 정보 저장하기
	editTestResultInfo: async({commit, dispatch}, {id, body}) => {
		const res = await api.project.editTestResultInfo(id, body);
		dispatch('loadTestResult', id)
		return res;
	},
	//시험일지 - 연구실 환경값 가져오기
	loadEnvironment: async({state, commit}, params) => {
		const res = await api.project.getEnvironment(params);
		commit('setEnvironment', res)
	}
}

export default { namespaced: true, state, getters, mutations, actions }