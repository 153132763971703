class Dashboard {
	constructor(instance) {
		this.axios = instance;
	}
	//이슈보드
	async getIssue(params) {
		const response = await this.axios.get(`/dashboard/issue/list`, {params})
		return response.data;
	}
	//이슈보드 목록 가져오기
	async getIssueProject(params) {
		const response = await this.axios.get(`/dashboard/issue/project/list`, {params})
		return response.data;
	}
	//이슈보드 작성하기
	async addIssue(info) {
		const response = await this.axios.put(`/dashboard/issue`, info)
		return response.data;
	}
	//이슈보드 글 수정 (오직 상태)
	async updateIssueStatus(id, status) {
		const response = await this.axios.patch(`/dashboard/issue/${id}/status`, {status: status})
		return response.data
	}
	//이슈보드 글 삭제
	async deleteIssue() {
		const response = await this.axios.delete(`/dashboard/issue/${id}`)
		return response.data
	}
}

export default Dashboard