import detail from './detail'
import list from './list'

export default {
	namespaced: true,
	modules: {
		detail,
		list
	},
}
