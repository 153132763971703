import analysis from './analysis'
import main from './main'
import admin from './admin'

export default {
	namespaced: true,
	modules: {
		analysis,
		main,
		admin
	},
}
