<template>
	<div class="card-layout">
		<h5 v-if="title" class="card-title">{{title}}</h5>
		<div class="card-content" v-html="content"/>
		<slot/>
	</div>
</template>

<script>
export default {
	name: 'CardLayout',
	props: {
		title: {
			type: String,
			default: ''
		},
		content: {
			type: String,
			default: ''
		}
	}
}
</script>

<style lang="scss">
//card
.card-layout {
	border-radius: 10px;
	border: 1px solid var(--ehti-ddd);
	position: relative;
	padding: 30px;
	h5 {
		font-size: 1.125rem;
		font-weight: 600;
	}
	&:has(canvas) {
		h5 {
			position: absolute;
		}
	}
	.card-content {
		font-size: 3rem;
		font-weight: 700;
		span {
			font-size: inherit;
		}
		> * {
			font-size: inherit;
			display: block;
		}
	}
}
</style>