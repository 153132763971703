class Client {
	constructor(instance) {
		this.axios = instance;
	}
	async getList(params) {
		try {
			const response = await this.axios.get(`/client/list`, {params})
	
			return response.data;
		} catch(e) {
			return e.response
		}
	}
	async getInfo(id) {
		try {
			const response = await this.axios.get(`/client/${id}?renderFormat=true`)
	
			return response.data;
		} catch(e) {
			return e.response
		}
	}
	async add(info) {
		try {
			const response = await this.axios.put(`/client`, info)
			return response.data;
		} catch(e) {
			return e.response
		}
	}
	async edit(id, body) {
		try {
			const response = await this.axios.patch(`/client/${id}`, body)
			return response.data;
		} catch(e) {
			return e.response
		}
	}
}

export default Client